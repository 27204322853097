


/* Main title styling */
.title {
    color: #ff95ba;
    line-height: 15vw;
    font-weight: bold;
}
.second-heading{
    color: white;
    width: 100%;
    text-align: center;
    font-size: 3vw;
}


.main-container {
    background-color: #0c0107;
    position: relative; // Ensure the container is positioned relatively for absolute positioning inside it
}
.center-all{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.home-p{
    color: white;
    font-size: 2.5rem;
    font-weight: lighter;
    text-align: justify;
}
.me-image{
    border-radius: 20% 80% 24% 76% / 69% 21% 79% 31%;
    border: 7px solid rgb(142, 32, 96);
    box-shadow: rgba(58, 8, 34, 0.25) 0px 54px 55px, rgba(115, 22, 73, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(131, 15, 83, 0.17) 0px 12px 13px, rgba(123, 18, 69, 0.09) 0px -3px 5px;;
}
.startup-p{
    color: white;
}
.startup-s-container{
    background-color: rgb(16, 1, 11);
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 3px solid rgb(64, 11, 31);
}
.startup-containers{
    padding: 40px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 3px solid rgb(64, 11, 31);
}

.speaker-images{
    height: 400px;
    object-fit: cover;
    width: 100%;
}
.speaker-title{
    color:white;
    font-size: 30px;
    margin-top: 5px;
}
.s-t-b{
    color: rgb(251, 126, 147);
}

.work-containers{
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    border: 3px solid rgb(64, 11, 31);
}
@media screen and (max-width:780px){
    .title{
     line-height:20vw;
    } 
    .second-heading{
 
     font-size: 5vw;
 }
 }