*{
    padding : 0;
    margin : 0;
    box-sizing : border-box;
    }
    
    .main-cont{
        width: 100%;
        // height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #000;
    }
    
    .main{
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
    
    .main::before, .main::after{
        content: '';
        position: absolute;
        z-index: 2;
        left: 50%;
        width: 400%;
        height: 500%;
        background-color: #000;
        border-radius: 50%;
        translate: -50% -2%;
    }

    .main::before{
        top: -480%;
    }
    
    .main::after{
        bottom: -480%;
    }
    
    .outer{
        position: absolute;
        display: flex;
        overflow-x: hidden;
        scroll-behavior: smooth;    
        scroll-snap-type: x mandatory;
    }
    
    .inner{
        position: relative;
        flex:  0 0 45%;
        width: 45%;
    }
    
    .slide{
        width: 100%;
        height:100%;
        border: 10px solid #000;
        scroll-snap-align: center;
    
    }
    
    .slide img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    @media screen and (max-width:780px){
        .main::before, .main::after{
            content: '';
            position: absolute;
            z-index: 2;
            left: 50%;
            width: 600%;
            height: 502%;
            background-color: #000;
            border-radius: 50%;
            translate: -50% -3%;
        }
        .main{
            height: 40vh;
        }
      
    }