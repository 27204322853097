@tailwind base;
@tailwind components;
@tailwind utilities;




@font-face {
  font-family: 'league';
  src: url('./fonts/league-gothic/theleagueof-league-gothic-64c3ede/LeagueGothic-CondensedRegular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'codec';
  src: url('./fonts/codec/Codec-Cold-Light-trial.ttf') format('truetype');
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: 'composebold';
  src: url('./fonts/compose-bold/Compose\ Bold/Compose Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.league{
  font-family: 'League';
}
.codec{
  font-family: codec;
}
.composebold{
  font-family: 'composebold';
}
body{
  background-color: rgb(0, 0, 0);
  overflow-x: hidden;
}

/* Width of the entire scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  background: #000000;
}

/* Handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #0e0e0e;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #090909;
}
a{
  text-decoration: underline;
}

/* globals */
.headings{
  font-size: 40px;
  color: white;
  font-weight: bold;
}

.color-pink{
  color: rgb(251, 126, 147);

}
@media screen and (max-width: 780px) {
  .headings{
    font-size: 25px;
  }

}

